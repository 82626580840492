// import BookPrices from './BookPrices'
// import WatchBookButton from './WatchBookButton'
import BookRowCard from '../stateful/BookRowCard';

class SeriesGrid extends React.Component {
  render() {
    // debugger
    const series = this.props.series;

    let books = null;
    let related = null;
    let covers = null;

    const dot = '.';
    const blank = '';
    const space = ' ';
    const hash = '#';
    /*
        let lbrac = '('
        let rbrac = ')'
                    { x.country ? lbrac : blank }
                    { x.country ? x.country : blank }
                    { x.country ? rbrac : blank }
                    */

    let sos_related = [];
    let sos_inseries = [];
    for (let i = 0; i < series.seriesorders.length; i++) {
      let so = series.seriesorders[i];
      if (so.ordr) {
        // keep same order
        sos_inseries.push(so);
      } else {
        // reverse order so most recent comes first
        sos_related.unshift(so);
      }
    }
    if (series.seriesorders) {
      // debugger
      books = sos_inseries.map((x) => <BookRowCard book={x.book} key={x.id} />);
      // books = sos_inseries.map((x) =>
      //     <BookRowCard book={x.book} key={x.id}
      //     handleVisibleChange={this.props.handleVisibleChange}
      //     />
      // )
      related = sos_related.map((x) => (
        <li key={x.id} className="bigspaces">
          <a href={x.book.url}>{x.book.title}</a>
        </li>
      ));
      /*
            covers = sos_inseries.map((x) =>
                <li key={x.id} className='bookcovers'>
                    <img src={x.book.image_url} className="bookcard_img" />
                </li>
            )
            */
    }
    return (
      <div className="cell">
        {books}
        <ul>{related}</ul>
      </div>
    );
  }
}

export default SeriesGrid;
