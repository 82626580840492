import {
  send_sub_admin_online,
  send_unsub_admin_online,
  ws,
} from '../../channels';

import { removeFromListById } from '../../functions';

class AdminOnlineContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      typ: 'admin_online',

      auth_users: [],
      num_lurkers: null,
    };

    this._onOpen = this.onOpen.bind(this);
    ws.addEventListener('open', this._onOpen);

    this._onClose = this.onClose.bind(this);
    ws.addEventListener('close', this._onClose);

    this._onMessage = this.onMessage.bind(this);
    ws.addEventListener('message', this._onMessage);
  }

  onOpen(evt) {
    this.sub_admin_online();
  }

  onClose(evt) {
    this.unsub_admin_online();
  }

  onMessage(evt) {
    const data = evt.data;
    const jdata = JSON.parse(data);

    console.log('AdminOnlineContent.onMessage');
    console.log(jdata);

    switch (jdata.typ) {
      case 'presence_all':
        this.setState({
          auth_users: jdata.auth_users,
          num_lurkers: jdata.num_lurkers,
        });
        break;

      // case 'presence_auth_user':
      //     let users = this.state.users
      //     removeFromListById(jdata, users)
      //     if (jdata.action == 'SAVE') {
      //         users.unshift(jdata)
      //     }

      //     this.setState({
      //         users: users,
      //     })
      //     break

      // case 'presence_num_lurkers':
      //     this.setState({
      //         num_lurkers: jdata.num_lurkers
      //     })
      //     break

      default:
        console.error('TODO: AdminOnlineContent.onMessage: ' + jdata.typ);
    }
  }

  sub_admin_online() {
    send_sub_admin_online();
  }

  unsub_admin_online() {
    send_unsub_admin_online();
  }

  render() {
    // debugger
    const state = this.state;
    let auth_users = '';

    // if (state.auth_users.length > 0) {
    auth_users = state.auth_users.map((x) => (
      <li key={x.id}>
        {x.username} {x.first_name}, {x.id}, {x.country}
      </li>
    ));
    // }

    return (
      <div>
        <h2>Users Online</h2>
        <ul>{auth_users}</ul>
        <h2>Lurkers</h2>
        {this.state.num_lurkers} anonymous users
      </div>
    );
  }
}

export default AdminOnlineContent;

// eof
