import {
  send_sub_author,
  send_unsub_author,
  send_sub_book,
  send_unsub_book,
  get_watch_author,
  ws,
} from '../channels';

import {
  contains,
  removeFromListById,
  sortByScore,
  sortByTitle,
} from '../functions';

import AuthorSidebar from '../stateless/AuthorSidebar';
import AuthorGrid from '../stateless/AuthorGrid';
import WatchAuthorButton from '../stateful/WatchAuthorButton';

class AuthorContent extends React.Component {
  constructor(props) {
    super(props);

    this.id = props.id;

    this.jdata = props.jdata;

    this.batch = 3;

    this.state = {
      // required
      typ: 'author',
      id: this.id,

      // specific to this type
      author: this.jdata,
    };

    this._onOpen = this.onOpen.bind(this);
    ws.addEventListener('open', this._onOpen);

    this._onClose = this.onClose.bind(this);
    ws.addEventListener('close', this._onClose);

    this._onMessage = this.onMessage.bind(this);
    ws.addEventListener('message', this._onMessage);

    // this._handleVisibleChange = this.handleVisibleChange.bind(this)
  }

  onOpen(evt) {
    this.sub_author();
    this.get_watch_author();
  }

  onClose(evt) {
    this.unsub_author();
    this.unsub_books();
  }

  onMessage(evt) {
    const data = evt.data;
    const jdata = JSON.parse(data);

    let author = this.state.author;

    switch (jdata.typ) {
      case 'livelog':
      case 'watch_author':
      case 'watch_book':
        return;
    }

    switch (jdata.typ) {
      case 'book':
        for (let i = 0; i < author.books.length; i++) {
          let book = author.books[i];
          if (contains(jdata.aliases, book.id)) {
            removeFromListById(jdata, author.books);
            if (jdata.action == 'SAVE') {
              author.books.unshift(jdata);
              author.books = author.books.sort(sortByTitle);
              author.books_by_score = author.books.sort(sortByScore);
            }

            this.setState({
              author: author,
            });
            break;
          }
        }
        break;

      case 'person':
        if (!contains(jdata.aliases, this.id)) {
          break;
        }

        let new_author = jdata;
        if (new_author.books.length) {
          new_author.books_by_score = author.books.sort(sortByScore);
        }

        // // keep current visibility
        // for (let i=0; i < new_author.books.length; i++) {
        //     let new_book = new_author.books[i]
        //     for (let j=0; j < old_books.length; j++) {
        //         let old_book = old_books[j]
        //         let old_book_keys = new Set(Object.keys(old_book))
        //         let new_book_keys = new Set(Object.keys(new_book))
        //         for (let k=0; k < old_book_keys.length; k++) {
        //             let key = old_book_keys[k]
        //             if (!new_book_keys.has(key)) {
        //                 // debugger
        //                 console.log("adding field " + key)
        //                 new_book[key] = old_book[key]
        //             } else if (new_book[key] === null) {
        //                 // debugger
        //                 console.log("changing null field " + key)
        //                 new_book[key] = old_book[key]
        //             }
        //         }
        //         if (new_book.id == old_book.id) {
        //             new_author.books[i].visible = old_book.visible
        //         }
        //     }
        // }

        this.setState({
          author: new_author,
        });

        // this.subInitialBooks()
        break;

      default:
        console.error('AuthorContent: typ: ' + jdata.typ);
    }
  }

  // subInitialBooks() {
  //   const author=this.state.author
  //   console.log("subInitialBooks: books")
  //     this.subTheseBooks(this.state.author.books, this.batch)
  //     console.log("subInitialBooks: books_by_score")
  //     this.subTheseBooks(this.state.author.books_by_score, this.batch)
  // }
  //
  // subTheseBooks(books, howmany) {
  //     let stop = Math.min(books.length, howmany)
  //     for (let i=0; i < stop; i++) {
  //         let book = books[i]
  //         console.log('subTheseBooks ' + book.id)
  //         if (!book.visible) {
  //           console.log('book not visible: ' + book.id)
  //             send_sub_book(book.id)
  //         }
  //         else {
  //           console.log('book not visible: ' + book.id)
  //         }
  //     }
  // }

  sub_author() {
    send_sub_author(this.id);
  }

  unsub_author() {
    send_unsub_author(this.id);
  }

  get_watch_author() {
    get_watch_author(this.id);
  }
  // handleVisibleChange(book_id, visible) {
  //     console.log('handleVisibleChange: ' + book_id + ' visible: ' + visible)
  //     let author = this.state.author
  //
  //     let changed = false
  //     let found = false
  //     let more = 3
  //     for (let i=0; i < author.books.length; i++) {
  //         let book = author.books[i]
  //         if (book.id === book_id) {
  //             found = true
  //         }
  //         if (!found) {
  //             continue
  //         }
  //         if (visible && !book.visible) {
  //             send_sub_book(book.id)
  //             changed = true
  //         }
  //         more = more - 1
  //         if (!more) {
  //             break
  //         }
  //     }
  //     if (changed) {
  //         author.books_by_score = author.books.sort(sortByScore)
  //         this.setState({
  //             author: author,
  //         })
  //     }
  // }

  // getAuthorBooks(orderby, startafter, qty) {
  //     send_get_author_books(this.id, orderby, startafter, qty)
  // }

  // sub_books() {
  //     // this gets the covers, alerts, prices
  //     // TODO: paginate to help with author with a thousand books
  //     // e.g. Stephen King
  //     for (let i=0; i<this.state.author.books.length; i++) {
  //         let book=this.state.author.books[i]
  //         send_sub_book(book.id)
  //     }
  // }

  unsub_books() {
    for (let i = 0; i < this.state.author.books.length; i++) {
      let book = this.state.author.books[i];
      send_unsub_book(book.id);
    }
  }

  render() {
    const author = this.state.author;
    if (author == null) {
      return <p>Waiting for data</p>;
    }
    return (
      <div className="grid-x">
        <div className="cell small-12 medium-9" id="main">
          <WatchAuthorButton id={author.id} watch={author.watch} />
          <AuthorGrid
            itemScope
            itemType="http://schema.org/Person"
            author={author}
            handleVisibleChange={this._handleVisibleChange}
          />
        </div>
        <div className="cell medium-3 show-for-medium">
          <AuthorSidebar author={author} />
        </div>
      </div>
    );
  }
}

export default AuthorContent;

// eof
