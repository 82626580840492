// import moment from 'moment'

// import SR_AuthorCard from './search_results/SR_AuthorCard'
// import SR_BookCard from './search_results/SR_BookCard'
// import SR_SeriesCard from './search_results/SR_SeriesCard'

import AuthorCellCard from './AuthorCellCard';
import AuthorRowCard from './AuthorRowCard';
import BookCellCard from './BookCellCard';
import BookCellCard2 from './BookCellCard2';
import SeriesRowCard from './SeriesRowCard';

class SearchResultCard extends React.Component {
  render() {
    const sr = this.props.sr;

    // console.log('SearchResultCard')
    // console.log(sr)

    let columns = null;

    switch (sr.typ) {
      case 'noresults':
        columns = (
          <div className="column">
            <p className="lead center">
              We're sorry, we didn't find any results for that search.
            </p>
            <p className="lead center">
              Please try again with an alternative search.
            </p>
          </div>
        );
        break;

      // case 'sr_book':
      case 'book':
        return <BookCellCard2 book={sr} />;
        break;

      // case 'sr_author':
      case 'person':
        // debugger
        // <SR_AuthorCard author={sr.result} />
        const author = sr;
        if (author.score === 0 || author.score === null)
          return <AuthorCellCard author={author} />;
        else return <AuthorRowCard author={author} />;
        break;

      // case 'sr_series':
      case 'series':
        return <SeriesRowCard series={sr} />;
        break;

      default:
        // debugger
        columns = (
          <div className="column">
            <p className="lead center">unknown type {sr.typ}</p>
          </div>
        );
        break;
    }

    if (columns === undefined) {
      debugger;
    }
    return <div className="row callout">{columns}</div>;
  }
}

export default SearchResultCard;
