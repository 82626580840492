class AuthorCellCard extends React.Component {
  constructor(props) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    location.href = this.props.book.url;
  }

  render() {
    let author = this.props.author;

    // <div className="callout">
    // <div className="column">
    return (
      <div className="card">
        <h2 className="trim-info">
          <a href={author.url}>{author.name}</a>
        </h2>
      </div>
    );
  }
}

export default AuthorCellCard;

// eof
