class AuthorRowCard extends React.Component {
  constructor(props) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    location.href = this.props.book.url;
  }

  render() {
    let author = this.props.author;

    const scored_books = author.scored_books;
    const unscored_books = author.unscored_books;

    let sb = null;
    let sb_images = null;
    let ub = null;
    let ub_images = null;

    if (author.scored_books) {
      sb = scored_books.map((x) => (
        <li key={x.key} className="bigspaces">
          <a href={x.url}>{x.title}</a>
        </li>
      ));
      sb_images = scored_books.map((x) => (
        <li key={x.key} className="bookcovers">
          <Image url={x.image_url} />
        </li>
      ));
    }

    if (author.unscored_books) {
      ub = unscored_books.map((x) => (
        <li key={x.key} className="bigspaces">
          <a href={x.url}>{x.title}</a>
        </li>
      ));
      ub_images = unscored_books.map((x) => (
        <li key={x.key} className="bookcovers">
          <Image url={x.image_url} />
        </li>
      ));
    }
    let series = null;
    if (author.series) {
      series = author.series.map((x) => (
        <li key={x.key} className="bigspaces">
          <a href={x.url}>{x.name}</a>
        </li>
      ));
    }

    // Add LH slug/imageplus
    // Add tooltop on long name/title

    return (
      <div className="card">
        <div className="card-section">
          <h2 className="trim-info">
            Author: <a href={author.url}>{author.name}</a>
          </h2>
        </div>
      </div>
    );

    /* LATER */
    // return (
    // <div className='card'>
    //     <div className='card-section'>
    //         <h2 className='trim-info'>
    //             Author: <a href={author.url}>{author.name}</a>
    //         </h2>
    //         <div className='row column'>
    //             <ul className='no-bullet'>
    //                 { sb ? sb_images : ub_images }
    //             </ul>
    //         </div>
    //     </div>
    //     <div className='card-section'>
    //         <div className='row column'>
    //             <p>
    //                 <a href={author.url} className="hollow button">Click for more</a>
    //             </p>
    //             <dl>
    //                 <dt>Books</dt>
    //                 <dd>
    //                     <ul className='no-bullet'>
    //                     { sb ? sb : ub }
    //                     </ul>
    //                 </dd>
    //             </dl>
    //         </div>
    //     </div>

    //     { author.series ?
    //     <div className='card-section'>
    //         <div className='row column'>
    //             <dl>
    //                 <dt>Series</dt>
    //                 <dd>
    //                     <ul className='no-bullet'>
    //                     { series }
    //                     </ul>
    //                 </dd>
    //             </dl>
    //         </div>
    //     </div>
    //   : ''
    //   }
    // </div>
    // )
  }
}

export default AuthorRowCard;

// eof
