import {
  send_sub_author,
  send_unsub_author,
  send_sub_book,
  send_unsub_book,
  send_sub_series,
  send_unsub_series,
  ws,
} from '../channels';

import {
  contains,
  removeFromListById,
  sortByScore,
  sortByTitle,
} from '../functions';

import AuthorSidebar from '../stateless/AuthorSidebar';
import SeriesGrid from '../stateless/SeriesGrid';

class SeriesContent extends React.Component {
  constructor(props) {
    super(props);

    this.id = props.id;

    this.jdata = props.jdata;

    this.batch = 3;

    this.state = {
      // required
      typ: 'series',
      id: this.id,

      // specific to this type
      series: this.jdata,
    };

    this._onOpen = this.onOpen.bind(this);
    ws.addEventListener('open', this._onOpen);

    this._onClose = this.onClose.bind(this);
    ws.addEventListener('close', this._onClose);

    this._onMessage = this.onMessage.bind(this);
    ws.addEventListener('message', this._onMessage);

    this._handleVisibleChange = this.handleVisibleChange.bind(this);
  }

  onOpen(evt) {
    this.sub_series();
  }

  onClose(evt) {
    this.unsub_series();
    this.unsub_books();
  }

  onMessage(evt) {
    const data = evt.data;
    const jdata = JSON.parse(data);

    switch (jdata.typ) {
      case 'livelog':
      case 'watch_book':
        return;
    }

    let series = this.state.series;

    switch (jdata.typ) {
      case 'book':
        for (let i = 0; i < series.seriesorders.length; i++) {
          let so = series.seriesorders[i];
          if (contains(jdata.aliases, so.book.id)) {
            let book = jdata;
            book.visible = true;
            series.seriesorders[i].book = book;
            this.setState({
              series: series,
            });
            break;
          }
        }
        break;

      case 'person':
        if (contains(jdata.aliases, this.state.series.author.id)) {
          let author = jdata;
          author.books = author.books.sort(sortByTitle);
          author.series = author.series.sort(sortByScore);
          series.author = author;
          this.setState({
            series: series,
          });
        }
        break;

      case 'series':
        if (contains(jdata.aliases, this.id)) {
          if (jdata.action == 'SAVE') {
            this.setState({
              series: jdata,
            });
            this.sub_author();
          }
        }
        break;

      default:
        console.error('SeriesContent: typ: ' + jdata.typ);
    }
  }

  // subInitialBooks() {
  //   return
  //   const series=this.state.series
  //     this.subTheseSeriesOrders(series.seriesorders.books, this.batch)
  //     // console.log('sub books by score')
  //     // this.subTheseBooks(this.state.author.books_by_score, this.batch)
  // }
  //
  // subTheseBooks(books, howmany) {
  //   console.log('subTheseBooks:')
  //   console.log(books)
  //     let stop = Math.min(books.length, howmany)
  //     for (let i=0; i < stop; i++) {
  //         let book = books[i]
  //         if (!book.visible) {
  //             console.log('sub ' + book.id)
  //             send_sub_book(book.id)
  //         }
  //     }
  // }

  handleVisibleChange(book_id, visible) {
    // debugger
    // console.log('handleVisibleChange: ' + book_id + ' visible: ' + visible)
    let series = this.state.series;

    let changed = false;
    let found = false;
    let more = 3;
    for (let i = 0; i < series.seriesorders.length; i++) {
      let so = series.seriesorders[i];
      let book = so.book;
      if (book.id === book_id) {
        found = true;
      }
      console.log('book.id=' + book.id + ', book_id=' + book_id);
      console.log(
        'found=' +
          found +
          ', visible=' +
          visible +
          ', so.book.visible=' +
          book.visible,
      );
      if (!found) {
        continue;
      }
      if (visible && !book.visible) {
        // console.log('send_sub_book: ' + book.id)
        send_sub_book(book.id);
        series.seriesorders[i].book.visible = visible;
        changed = true;
      }
      more = more - 1;
      if (!more) {
        break;
      }
    }
    if (changed) {
      // author.books_by_score = author.books.sort(sortByScore)
      this.setState({
        series: series,
      });
    }
  }

  sub_series() {
    // this gets the basic book info but not the covers or prices
    const series_id = this.id;
    send_sub_series(series_id);
  }

  unsub_series() {
    const series_id = this.id;
    send_unsub_series(series_id);
  }

  sub_author() {
    const author_id = this.state.series.author.id;
    send_sub_author(author_id);
  }

  unsub_author() {
    const author_id = this.state.series.author.id;
    send_unsub_author(author_id);
  }

  sub_books() {
    // this gets the covers, alerts, prices
    for (let i = 0; i < this.state.series.seriesorders.length; i++) {
      let so = this.state.series.seriesorders[i];
      send_sub_book(so.book.id);
    }
  }

  unsub_books() {
    for (let i = 0; i < this.state.series.seriesorders.length; i++) {
      let so = this.state.series.seriesorders[i];
      send_unsub_book(so.book.id);
    }
  }

  render() {
    if (this.state.series == null) {
      return <p>Waiting for data</p>;
    }
    const series = this.state.series;
    return (
      <div className="grid-x">
        <div className="cell small-12 medium-9" id="main">
          <SeriesGrid
            itemScope
            itemType="http://schema.org/Series"
            series={this.state.series}
            handleVisibleChange={this._handleVisibleChange}
          />
        </div>
        <div className="cell medium-3 show-for-medium">
          <AuthorSidebar author={this.state.series.author} />
        </div>
      </div>
    );
  }
}

export default SeriesContent;

// eof
