class SearchResultMinBook extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const book = this.props.book;
    const space = ' ';
    return (
      <li className="bigspaces">
        <a href={book.url}>{book.title}</a>, by{space}
        <a href={book.mainauthor.url}>{book.authorstring}</a>
      </li>
    );
  }
}

export default SearchResultMinBook;
