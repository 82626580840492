import { sortByScore, sortByTitle } from '../functions';

import SeriesSidebarBlock from '../stateless/SeriesSidebarBlock';
import BookSidebarBlock from '../stateless/BookSidebarBlock';

class AuthorSidebar extends React.Component {
  render() {
    const author = this.props.author;

    const series = author.series.sort(sortByScore);
    const books = author.books.sort(sortByTitle);

    const seriesSidebar = series.map((x) => (
      <SeriesSidebarBlock key={x.id} series={x} />
    ));

    const booksSidebar = books.map((x) => (
      <BookSidebarBlock key={x.id} book={x} />
    ));

    return (
      <div className="sidebar sticky" data-sticky data-anchor="main">
        <div className={'card' + (series.length === 0 ? ' hide' : '')}>
          <div className="card-divider">
            <h2>Series</h2>
          </div>
          <div className="card-section">{seriesSidebar}</div>
        </div>
        <div className={'card' + (books.length === 0 ? ' hide' : '')}>
          <div className="card-divider">
            <h2>Books</h2>
          </div>
          <div className="card-section">{booksSidebar}</div>
        </div>
      </div>
    );
  }
}

export default AuthorSidebar;

// eof
