import moment from 'moment';

import {
  send_sub_admin_livelog,
  send_unsub_admin_livelog,
  ws,
} from '../../channels';

import { removeFromListById } from '../../functions';

function Actions(x) {
  if (x.actions.length === 0) {
    return null;
  }

  const colon = ':';
  const space = ' ';
  const actions = x.actions.map((x) => (
    <li key={x.id}>
      {moment.unix(x.when).fromNow()}
      {space}{colon}<b>{x.who}</b>
      {space}{colon}
      {x.msg && x.link ? (
        <>
          {x.msg}
          <br />
          <a href={x.link} target="_blank" rel="noopener noreferrer">{x.link}</a>
        </>
      ) : x.msg ? (
        x.msg
      ) : x.link ? (
        <a href={x.link} target="_blank" rel="noopener noreferrer">{x.link}</a>
      ) : null}
    </li>
  ));

  return (
    <div>
      <h2>Livelog</h2>
      <ul>{actions}</ul>
    </div>
  );

  // return (
  //     <div>
  //         <h2>Auth Users Online</h2>
  //         {x.users.length} auth users:
  //         <ul className='big_spaces'>
  //             {users}
  //         </ul>
  //     </div>
  // )
}

function AuthUsers(x) {
  if (x.users === null) {
    return null;
  }

  const users = x.users.map((x) => <li key={x.id}>{x.username}</li>);

  return (
    <div>
      <h2>Auth Users Online</h2>
      {x.users.length} auth users:
      <ul className="big_spaces">{users}</ul>
    </div>
  );
}

function Lurkers(x) {
  if (x.num === null) {
    return null;
  }

  return (
    <div>
      <h2>Lurkers</h2>
      <p>{x.num} anonymous users</p>
    </div>
  );
}

class AdminLivelogContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      typ: 'livelog',

      auth_users: null,
      num_lurkers: null,
      actions: [],
    };

    this._onOpen = this.onOpen.bind(this);
    ws.addEventListener('open', this._onOpen);

    this._onClose = this.onClose.bind(this);
    ws.addEventListener('close', this._onClose);

    this._onMessage = this.onMessage.bind(this);
    ws.addEventListener('message', this._onMessage);
  }

  onOpen(evt) {
    this.sub_admin_livelog();
  }

  onClose(evt) {
    this.unsub_admin_livelog();
  }

  onMessage(evt) {
    const data = evt.data;
    const jdata = JSON.parse(data);

    // console.log('AdminLivelogContent.onMessage')
    // console.log(jdata)

    switch (jdata.typ) {
      case 'presence_all':
        this.setState({
          auth_users: jdata.auth_users,
          num_lurkers: jdata.num_lurkers,
        });
        break;

      case 'livelog':
        let actions = this.state.actions;
        let log = JSON.parse(jdata.content);
        actions.unshift(log);
        this.setState({
          actions: actions,
        });
        break;

      // case 'presence_auth_user':
      //     let users = this.state.users
      //     removeFromListById(jdata, users)
      //     if (jdata.action == 'SAVE') {
      //         users.unshift(jdata)
      //     }

      //     this.setState({
      //         users: users,
      //     })
      //     break

      // case 'presence_num_lurkers':
      //     this.setState({
      //         num_lurkers: jdata.num_lurkers
      //     })
      //     break

      default:
        console.error('TODO: AdminLivelogContent.onMessage: ' + jdata.typ);
    }
  }

  sub_admin_livelog() {
    send_sub_admin_livelog();
  }

  unsub_admin_livelog() {
    send_unsub_admin_livelog();
  }

  render() {
    // debugger
    const colon = ':';
    const state = this.state;
    if (state.auth_users !== null) {
      const num_auth_users = state.auth_users.length;
    }

    return (
      <div>
        <AuthUsers users={state.auth_users} />
        <Lurkers num={state.num_lurkers} />
        <Actions actions={state.actions} />
      </div>
    );
  }
}

export default AdminLivelogContent;

// eof
