class BookScore extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const book = this.props.book;
    // TODO
    return null;
    // return (
    //     <div className="grid-y grid-frame">
    //         <div className="cell">
    //             <i className='fa fa-2x fa-caret-up' />
    //         </div>
    //         <div>
    //             {book.score}
    //         </div>
    //     </div>
    // )
  }
}

export default BookScore;

// eof
