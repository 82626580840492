class BookSidebarBlock extends React.Component {
  render() {
    const book = this.props.book;

    return (
      <div className="sidebar">
        <a className="sidebar" href={book.url}>
          {book.title}
        </a>
      </div>
    );
  }
}

export default BookSidebarBlock;

// eof
