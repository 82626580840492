import moment from 'moment';

//

function sortByFreshnessAndPrice(a, b) {
  let rtn = null;
  if (a.fresh && !b.fresh) {
    rtn = -1;
  } else if (!a.fresh && b.fresh) {
    rtn = 1;
  } else if (a.numericprice > b.numericprice) {
    rtn = 1;
  } else if (a.numericprice < b.numericprice) {
    rtn = -1;
  } else {
    rtn = 0;
  }
  return rtn;
}

class BookPrices extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const blis = this.props.blis;

    if (!blis) {
      return <p>No Prices</p>;
    }
    let ordered = blis.sort(sortByFreshnessAndPrice);

    let rows = ordered.map((x) => (
      <tr key={x.id}>
        <td>
          <a href={x.buy_url_hash} target="_blank">
            {x.vendor_name}
          </a>
        </td>
        <td className="lead">{x.price_html}</td>
        <td>{moment(x.checked).calendar()}</td>
        <td>{moment(x.changed).calendar()}</td>
        <td></td>
      </tr>
    ));

    return (
      <div>
        <table className="hover book_prices">
          <thead>
            <tr>
              <th>Buy From</th>
              <th>Price</th>
              <th>Checked</th>
              <th>Changed</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }
}

export default BookPrices;
