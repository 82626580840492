import Waypoint from 'react-waypoint';
import RobustWebsocket from 'robust-websocket';

import BookPrices from '../stateless/BookPrices';
import BookScore from '../stateless/BookScore';
import BookLabels from '../stateless/BookLabels';
import Order from '../stateless/Order';
import WatchBookButton from '../stateful/WatchBookButton';
import ShowPricesButton from '../stateless/ShowPricesButton';

import { send_sub_book, send_unsub_book, ws } from '../channels';
import { contains } from '../functions';

function TitleAuthor(x) {
  return (
    <div>
      <h2 className="trim-info">
        <a href={x.url}>{x.title}</a>
      </h2>
      <div>
        <i>{x.subtitle}</i>
      </div>
      by <a href={x.mainauthor_url}>{x.authorstring}</a>
    </div>
  );
}

function Author(x) {
  return <a href={x.url}>{x.name}</a>;
}

function Title(x) {
  return (
    <div>
      <h2 className="trim-info">
        <a href={x.url}>{x.title}</a>
      </h2>
      <div>
        <i>{x.subtitle}</i>
      </div>
    </div>
  );
}

function EmptyWhenVisible(x) {
  if (!x.visible) {
    // console.log("not visible")
    return null;
  }
  if (x.empty !== true) {
    // console.log("not empty")
    return null;
  }
  // console.log("empty EWV")
  // return
  // return<WatchBookButton id={x.id} visible={x.visible} />
  return (
    <div>
      <p>
        <i>No prices found.</i>
      </p>
    </div>
  );
}

class BookRowCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      book: props.book,
      visible: false,
      empty: null,
      placeholder: true,
      // prices_visible: visible,
    };

    // This binding is necessary to make `this` work in the callback
    this._handleClick = this.handleClick.bind(this);
    // this._toggleShowPrices = this.toggleShowPrices.bind(this)

    this._onMessage = this.onMessage.bind(this);
    ws.addEventListener('message', this._onMessage);

    this._enter = this.enter.bind(this);
    this._exit = this.exit.bind(this);
  }

  onMessage(evt) {
    const data = evt.data;
    const jdata = JSON.parse(data);

    switch (jdata.typ) {
      case 'livelog':
      case 'person':
      case 'series':
      case 'watch_author':
      case 'watch_book':
        return;
    }

    // console.log("jdata.typ=" + jdata.typ)
    // console.log("jdata.action=" + jdata.action)

    switch (jdata.typ) {

      case 'book':
        // if (!contains(jdata.aliases, this.state.book.id)) {
        //   return
        // }
        // debugger();
        // console.log("this book? " + (jdata.id != this.state.book.id));
        if (jdata.id != this.state.book.id) {
          return;
        }

        switch (jdata.action) {
          case 'SAVE':
            // console.log(jdata)
            // debugger
            let empty = !jdata.blis.length;
            // console.log('empty: ' + empty)
            this.setState({
              book: jdata,
              visible: true,
              empty: empty,
              placeholder: false,
            });
            break;

          case 'DEL':
            // debugger;
            // console.log(this.state);
            send_unsub_book(this.state.book.id);
            // this.setState({
            //   book: {},
            //   visible: false,
            //   empty: true,
            //   placeholder: false,
            // });
            break;

          default:
            console.error('BAD: ' + jdata.action);
        }
        break;

      default:
        console.error('BAD: typ: ' + jdata.typ);
    }
  }

  enter() {
    // console.log('enter: ' + this.state.book.title)
    if (this.visible) {
      return;
    }

    // console.log('send_sub_book: ' + this.state.book.id)
    send_sub_book(this.state.book.id);
  }

  exit(x) {
    // console.log('exit: ' + this.state.book.title)
    if (!this.visible) {
      return;
    }

    // console.log('send_unsub_book: ' + this.state.book.id)
    send_unsub_book(this.state.book.id);
    this.setState({
      visible: false,
    });
  }

  handleClick() {
    const b = this.state.book;
    location.href = this.state.book.url;
  }

  render() {
    const book = this.state.book;
    const space = ' ';
    const hash = '#';

    // console.log('watch:')
    // console.log(book.watch)

    console.log("187: render: " + book)
    const empty = (
      <div className="grid-x callout my_callout">
        <div className="cell small-3">
          <a href={book.url}>
            <img className="bookcard_img" src={book.image_url} />
          </a>
        </div>
        <div className="cell small-9">
          <TitleAuthor
            title={book.title}
            subtitle={book.subtitle}
            url={book.url}
            authorstring={book.authorstring}
            mainauthor_url={book.mainauthor.url}
          />
          <Order seriesorder={book.seriesorder} />
          <EmptyWhenVisible
            id={book.id}
            visible={this.state.visible}
            empty={this.state.empty}
          />
        </div>
      </div>
    );
    const full = (
      <div className="grid-x callout my_callout">
        <div className="cell small-3">
          <a href={book.url}>
            <img className="bookcard_img" src={book.image_url} />
          </a>
        </div>
        <div className="cell small-1">
          <BookScore book={book} />
        </div>
        <div className="cell small-8">
          <TitleAuthor
            title={book.title}
            subtitle={book.subtitle}
            url={book.url}
            authorstring={book.authorstring}
            mainauthor_url={book.mainauthor.url}
          />
          <Order seriesorder={book.seriesorder} />
          <BookLabels labels={book.labels} />
        </div>

        <div className="cell small-3">
          <div className="watch_price_hdr">Best Price</div>
          <div className="watch_price">{book.bestprice}</div>
        </div>
        <div className="cell small-9">
          <WatchBookButton id={book.id} watch={book.watch} />
        </div>

        <div className="cell small-3">
          <ShowPricesButton
            visible={this.state.visible}
            toggle={this.toggleShowPrices}
          />
        </div>
        <div className="cell small-9"></div>
        <div className={'cell small-12' + (this.state.visible ? '' : ' hide')}>
          <BookPrices blis={book.blis} />
        </div>
      </div>
    );
    const content = this.state.placeholder || this.state.empty ? empty : full;
    // console.log('rendering content: empty = ' + this.state.empty)

    return (
      <Waypoint onEnter={this._enter} onExit={this._exit}>
        {content}
      </Waypoint>
    );
  }
}

export default BookRowCard;

// eof
