import { change_watch_book, get_watch_book, ws } from '../channels';

import { contains } from '../functions';

class WatchBookButton extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.id;
    this.state = {
      // placeholder: (!this.props.visible),
      watch: this.props.watch,
      waiting: null,
    };

    // console.log('WBB: id: ' + this.id + ' ph: ' + this.state.placeholder)

    // This binding is necessary to make `this` work in the callback
    this.toggleWatch = this.toggleWatch.bind(this);

    this._onMessage = this.onMessage.bind(this);
    ws.addEventListener('message', this._onMessage);
  }

  onMessage(evt) {
    let data = evt.data;
    let jdata = JSON.parse(data);

    switch (jdata.typ) {
      case 'book':
      case 'btu':
      case 'livelog':
      case 'person':
      case 'series':
      case 'sr_author':
      case 'sr_book':
      case 'sr_series':
      case 'watch_author':
        return;
    }

    switch (jdata.typ) {
      // case 'book':
      //   if (!contains(jdata.aliases, this.id)) {
      //     return
      //   }
      //   if (jdata.action == 'SAVE') {
      //     debugger
      //     console.log(jdata)
      //     // this.get_watch_book()
      //     this.setState({
      //         watch: jdata.watch,
      //         waiting: false,
      //         // placeholder: false,
      //     })
      //   }
      //   break

      case 'watch_book':
        // if (contains(jdata.alias_ids, this.id)) {
        if (this.id === jdata.id) {
          this.setState({
            watch: jdata.watch,
            waiting: false,
            placeholder: false,
          });
        }
        break;

      default:
        console.error('WatchBookButton: typ: ' + jdata.typ);
    }
  }

  // get_watch_book() {
  //     get_watch_book(this.id)
  // }

  toggleWatch(event) {
    event.stopPropagation();
    const watch = this.state.watch;
    if (watch.anon === true) {
      return;
    }
    const newstate = !watch.book;
    this.setState({
      waiting: true,
    });
    change_watch_book(this.id, newstate);
  }

  render() {
    const watch = this.state.watch;
    const waiting = this.state.waiting;

    const placeholder = (
      <button className="button tiny disabled">Watchlist</button>
    );

    let real = null;
    if (watch) {
      real = (
        <button
          className={
            'button tiny' +
            (watch.anon ? ' disabled' : '') +
            (watch.book ? ' hollow' : '')
          }
          onClick={this.toggleWatch}
        >
          {watch.book ? 'Remove from ' : 'Add to '}
          Watchlist
          <img
            className={'spinner' + (waiting ? '' : ' hide')}
            src="/static/images/vector-circle-loaders/loading-animation-10.gif"
          />
        </button>
      );
    }
    const button = this.state.placeholder ? placeholder : real;

    return (
      <div className="grid-x">
        <div
          className="cell
                    small-10 small-offset-1
                    medium-6 medium-offset-3"
        >
          {button}
        </div>
        <div className="auto cell" />
      </div>
    );
  }
}

export default WatchBookButton;
