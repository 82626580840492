import {
  send_sub_author,
  send_unsub_author,
  send_sub_book,
  send_unsub_book,
  // send_watch_author,
  // send_watch_book,
  ws,
} from '../channels';

import { contains, removeFromListById } from '../functions';

import AuthorSidebar from '../stateless/AuthorSidebar';
import BookLabels from '../stateless/BookLabels';
import BookPrices from '../stateless/BookPrices';
// import WatchAuthorButton from '../stateful/WatchAuthorButton'
import WatchBookButton from '../stateful/WatchBookButton';

function Limit(x) {
  // WIP
  return null;

  debugger;
  if (!x.show) {
    return null;
  }

  return (
    <div className="input-group">
      <span className="input-group-label limitlabel">
        Only send alerts when the price is below (£)
      </span>
      <input
        className="input-group-field"
        type="number"
        value={x.target.price}
      />
    </div>
  );
}

function Book(x) {
  const book = x.book;
  if (!book.id) {
    return null;
  }

  return (
    <div className="grid-x">
      <div className="cell small-4">
        <Image bookcover_url={book.image_url} />
      </div>

      <div className="cell small-8">
        <BookMetadata
          title={book.title}
          subtitle={book.subtitle}
          authorstring={book.authorstring}
          author_url={book.mainauthor.url}
          seriesorder={book.seriesorder}
          numpages_txt={book.numpages_txt}
          labels={book.labels}
        />
        <WatchBookButton id={book.id} watch={book.watch} />
      </div>

      <div className="cell">
        <Rcmds />
      </div>

      <div className="cell">
        <Limit show={book.watch.book} target={book.target} />
        <BookPrices blis={book.blis} />
      </div>
    </div>
  );
}

// Add Pubdate, Publisher, to this

function BookMetadata(x) {
  // console.log(x)
  return (
    <div>
      <div className="book_title_author">
        <div className="book_title">{x.title}</div>
        <div className="subtitle">{x.subtitle}</div>
        {x.authorstring ? ' by ' : ''}
        <a href={x.author_url} className="">
          {x.authorstring}
        </a>
        <SeriesOrder info={x.seriesorder} />
      </div>

      <div className="book_metadata">
        <NumPages txt={x.numpages_txt} />
        <BookLabels labels={x.labels} />
      </div>
    </div>
  );
}

function Image(x) {
  return <img className="img-book-detail thumbnail" src={x.bookcover_url} />;
}

function NumPages(x) {
  if (!x.txt) {
    return <span />;
  }

  return (
    <dl>
      <dt>Number of Pages:</dt>
      <dd>{x.txt} pages</dd>
    </dl>
  );
}

function Ordr(x) {
  let hash = '#';
  let dot = '.';
  if (x.ordr2) {
    return (
      <span>
        {hash}
        {x.ordr}
        {dot}
        {x.ordr2}
      </span>
    );
  } else {
    return (
      <span>
        {hash}
        {x.ordr}
      </span>
    );
  }
}

function Rcmds(x) {
  return null;
  // return (
  // <p>TODO: Rcmds</p>
  // )
}

function SeriesOrder(x) {
  let space = ' ';
  let so = x.info;
  if (!so.ordr) {
    return <span />;
  }

  return (
    <div>
      <Ordr ordr={so.ordr} ordr2={so.ordr2} />
      {space} in the {space}
      <a href={so.series.url}>{so.series.name}</a>
      {space} Series
    </div>
  );
}

class BookContent extends React.Component {
  constructor(props) {
    super(props);
    this.id = props.id;
    this.jdata = props.jdata;

    this.state = {
      // required
      typ: 'book',
      id: this.id,

      // specific

      book: this.jdata.book,
      author: this.jdata.author,
    };

    this._onOpen = this.onOpen.bind(this);
    ws.addEventListener('open', this._onOpen);

    this._onClose = this.onClose.bind(this);
    ws.addEventListener('close', this._onClose);

    this._onMessage = this.onMessage.bind(this);
    ws.addEventListener('message', this._onMessage);
  }

  onOpen(evt) {
    this.sub_book();
    this.sub_author();
  }

  onClose(evt) {
    this.unsub_book();
    this.unsub_author();
  }

  onMessage(evt) {
    let data = evt.data;
    let jdata = JSON.parse(data);

    switch (jdata.typ) {
      case 'livelog':
      case 'watch_book':
        return;
    }

    switch (jdata.typ) {
      case 'book':
        if (contains(jdata.aliases, this.id)) {
          this.setState({
            book: jdata,
          });
          if (jdata.id != this.id) {
            this.unsub_book();
            this.id = jdata.id;
            this.sub_book();
          }
          // } else {
          //     console.log("ignoring book " + jdata.id)
        }
        break;

      case 'person':
        if (contains(jdata.aliases, this.id)) {
          author.books = author.books.sort(sortByScore);
          this.setState({
            author: jdata,
          });
        }
        break;

      default:
        // debugger
        console.error('BookContent: typ: ' + jdata.typ);
    }
  }

  sub_author() {
    send_sub_author(this.state.author.id);
  }

  unsub_author() {
    send_unsub_author(this.state.author.id);
  }

  sub_book() {
    send_sub_book(this.id);
  }

  unsub_book() {
    send_unsub_book(this.id);
  }

  render() {
    return (
      <div className="grid-x">
        <div className="cell small-12 medium-9" id="main">
          <div itemScope itemType="http://schema.org/Book">
            <Book book={this.state.book} />
          </div>
        </div>
        <div className="cell medium-3 show-for-medium">
          <AuthorSidebar author={this.state.author} />
        </div>
      </div>
    );
  }
}

export default BookContent;

// eof
