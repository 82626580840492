import { change_watch_author, get_watch_author, ws } from '../channels';

import { contains } from '../functions';

class WatchAuthorButton extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.id;
    this.state = {
      // placeholder: (!this.props.visible),
      watch: this.props.watch,
      waiting: null,
    };

    // This binding is necessary to make `this` work in the callback
    this.toggleWatch = this.toggleWatch.bind(this);

    this._onMessage = this.onMessage.bind(this);
    ws.addEventListener('message', this._onMessage);
  }

  onMessage(evt) {
    let data = evt.data;
    let jdata = JSON.parse(data);

    switch (jdata.typ) {
      case 'book':
      case 'btu':
      case 'livelog':
      case 'person':
      case 'series':
      case 'sr_author':
      case 'sr_book':
      case 'sr_series':
      case "watch_book":
        return;
    }

    switch (jdata.typ) {
      case 'watch_author':
        if (contains(jdata.aliases, this.id)) {
          this.setState({
            watch: jdata.watch,
            waiting: false,
            placeholder: false,
          });
        }
        break;

      default:
        console.error('WatchAuthorButton: typ: ' + jdata.typ);
    }
  }

  toggleWatch(event) {
    event.stopPropagation();
    const watch = this.state.watch;
    if (watch.anon === true) {
      return;
    }
    const newstate = !watch.author;
    this.setState({
      waiting: true,
    });
    change_watch_author(this.id, newstate);
  }

  render() {
    // debugger
    const watch = this.state.watch;
    const waiting = this.state.waiting;

    const placeholder = (
      <button className="button tiny disabled">Watchlist</button>
    );

    let real = null;
    if (watch) {
      real = (
        <button
          className={
            'button tiny float-center' +
            (watch.anon ? ' disabled' : '') +
            (watch.author ? ' hollow' : '')
          }
          onClick={this.toggleWatch}
        >
          {watch.author ? 'Unfollow ' : 'Follow '}
          Author
          <img
            className={'spinner' + (waiting ? '' : ' hide')}
            src="/static/images/vector-circle-loaders/loading-animation-10.gif"
          />
        </button>
      );
    }
    const button = this.state.placeholder ? placeholder : real;

    return (
      <div className="grid-x">
        <div
          className="cell
                    small-10 small-offset-1
                    medium-6 medium-offset-3"
        >
          {button}
        </div>
        <div className="auto cell" />
      </div>
    );
  }
}

export default WatchAuthorButton;
