import moment from 'moment';

import { send_sub_bookstream, send_unsub_bookstream, ws } from '../channels';

import { contains, removeFromListById, sortByFreshness } from '../functions';

import BookCellCard2 from '../stateless/BookCellCard2';
import BookRowCard from '../stateful/BookRowCard';

// DRY: v. similar to myPrices

class BookStreamContent extends React.Component {
  constructor(props) {
    super(props);
    this.id = props.id;

    this.state = {
      // required
      typ: 'bookStream',
      id: this.id,

      // specific to this type
      books: [],
    };

    this._onOpen = this.onOpen.bind(this);
    ws.addEventListener('open', this._onOpen);

    this._onClose = this.onClose.bind(this);
    ws.addEventListener('close', this._onClose);

    this._onMessage = this.onMessage.bind(this);
    ws.addEventListener('message', this._onMessage);
  }

  onOpen(evt) {
    this.sub_bookstream();
  }

  onClose(evt) {
    this.unsub_bookstream();
  }

  onMessage(evt) {
    let data = evt.data;
    let jdata = JSON.parse(data);

    switch (jdata.typ) {
      case 'livelog':
      case 'watch_book':
        return;
    }

    switch (jdata.typ) {
      case 'book':
        let books = this.state.books;
        removeFromListById(jdata, books);

        if (jdata.action == 'SAVE') {
          if (!jdata.blis) {
            return;
          }
          if (!jdata.changed_ts) {
            return;
          }
          books.unshift(jdata);
        } else if (jdata.action != 'DEL') {
          console.log('TODO: handle action ' + jdata.action);
        }

        this.sortAndSet(books);
        break;

      default:
        console.error('BookStreamContent: typ: ' + jdata.typ);
    }
  }

  sortAndSet(books) {
    let max_books = 20;
    let new_books = books.sort(sortByFreshness);
    let top_books = new_books.slice(0, max_books);

    this.setState({
      books: top_books,
    });
  }

  sub_bookstream() {
    send_sub_bookstream(this.id);
  }

  unsub_bookstream() {
    send_unsub_bookstream(this.id);
  }

  render() {
    const cards = this.state.books.map((book) => (
      <div key={book.id} className="cell">
        <BookRowCard key={book.id} book={book} />
      </div>
    ));
    return (
      <div>
        <div className="grid-x">
          {cards.length ? '' : <p>Waiting for the books.</p>}
          {cards}
        </div>
      </div>
    );
  }
}

export default BookStreamContent;

// eof
