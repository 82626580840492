import { isEmptyDict } from '../functions';

class Order extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let so = this.props.seriesorder;
    if (so == undefined || isEmptyDict(so)) {
      return null;
    }
    // console.log(so)
    let dot = '.';
    let blank = '';
    let space = ' ';
    let hash = '#';
    return (
      <div className="seriesorder">
        <a href={so.series.url}>{so.series.name}</a>
        {space}
        {so.ordr ? hash : blank}
        {so.ordr ? so.ordr : blank}
        {so.ordr2 ? dot : blank}
        {so.ordr2}
      </div>
    );
  }
}

export default Order;

// eof
