// import moment from 'moment'
import { sortByFreshnessAndPrice } from '../functions';

//

class Price extends React.Component {
  constructor(props) {
    super(props);
    this.bli = props.bli;
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    console.log('handleClick');
    event.stopPropagation();
    location.href = this.bli.buy_url_hash;
  }

  render() {
    return (
      <div className="grid-x" onClick={this.handleClick}>
        <div className="cell small-6">
          <a
            className="button small"
            href={this.bli.buy_url_hash}
            target="_blank"
          >
            <span className="smallfont nowrap">Buy From</span>
            <br />
            <span className="buybutton">{this.bli.vendor_name}</span>
          </a>
        </div>
        <div className="cell small-6">
          <a
            className="button small hollow"
            href={this.bli.buy_url_hash}
            target="_blank"
          >
            <span className="lead">{this.bli.price_html}</span>
          </a>
        </div>
      </div>
    );
  }
}

class BookPricesCell extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const blis = this.props.blis;

    if (!blis) {
      return <p>No Prices</p>;
    }
    let ordered = blis.sort(sortByFreshnessAndPrice);

    let buttons = ordered.map((x) => <Price bli={x} key={x.id} />);
    return <div>{buttons}</div>;
  }
}

export default BookPricesCell;
