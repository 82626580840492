import BookRowCard from '../stateful/BookRowCard';

class AuthorGrid extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let books = null;
    const author = this.props.author;
    if (author.books) {
      books = author.books_by_score.map((x) => (
        <BookRowCard
          key={x.id}
          book={x}
          handleVisibleChange={this.props.handleVisibleChange}
        />
      ));
    }

    return <div className="cell">{books}</div>;
  }
}

export default AuthorGrid;
