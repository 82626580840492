import moment from 'moment';
import RobustWebsocket from 'robust-websocket';

import AuthorContent from '../content/AuthorContent';
import BookContent from '../content/BookContent';
import BookStreamContent from '../content/BookStreamContent';
import MyPricesContent from '../content/MyPricesContent';
import SearchContent from '../content/SearchContent';
import SeriesContent from '../content/SeriesContent';

import AdminLivelogContent from '../content/my_admin/AdminLivelogContent';
import AdminOnlineContent from '../content/my_admin/AdminOnlineContent';

import { set_country, ws, set_ws } from '../channels';

function WarnOffline(x) {
  if (!x.show) {
    return null;
  }

  return (
    <div>
      <div className="cell">
        <div className="callout alert center">
          <button
            type="button"
            className="button secondary"
            onClick={x.handler}
          >
            Reconnect?
          </button>
        </div>
      </div>
    </div>
  );
}

class Content extends React.Component {
  constructor(props) {
    // console.log('Content.ctor')
    // debugger

    super(props);

    this.typ = props.typ;
    this.id = props.id;
    const data = props.data;

    this.jdata = JSON.parse(data);

    this.state = {
      connected: false,
      warn_offline: false,
    };

    moment.updateLocale('en', {
      calendar: {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: '[Last] dddd',
        nextWeek: '[Next] dddd',
        sameElse: 'll',
      },
    });

    let scheme = 'wss';
    if (window.location.host === 'localhost:8000') {
      scheme = 'ws';
    }
    if (window.location.host === '127.0.0.1:8000') {
      scheme = 'ws';
    }
    if (window.location.host === 'www1.hobthross.com:8001') {
      scheme = 'ws';
    }
    let wspath = '/ws/connect';
    let url = scheme + '://' + window.location.host + wspath;
    // console.log("url: " + url)
    let ws = new RobustWebsocket(url);
    set_ws(ws);

    this._onOpen = this.onOpen.bind(this);
    ws.addEventListener('open', this._onOpen);

    this._onClose = this.onClose.bind(this);
    ws.addEventListener('close', this._onClose);

    this.handleReopenClick = this.handleReopenClick.bind(this);
  }

  onOpen(evt) {
    // console.log("Content.onOpen")
    this.setState({
      connected: true,
      warn_offline: false,
    });
  }

  onClose(evt) {
    this.setState({
      connected: false,
      warn_offline: true,
    });
  }

  handleReopenClick() {
    ws.open();
  }

  render() {
    let content = null;
    switch (this.typ) {
      case 'livelog':
        content = <AdminLivelogContent />;
        break;

      case 'online':
        content = <AdminOnlineContent />;
        break;

      case 'author':
        content = <AuthorContent id={this.id} jdata={this.jdata} />;
        break;

      case 'book':
        content = <BookContent id={this.id} jdata={this.jdata} />;
        break;

      case 'bookStream':
        content = <BookStreamContent id={this.id} />;
        break;

      case 'myPrices':
        content = <MyPricesContent id={this.id} />;
        break;

      case 'search':
        content = <SearchContent id={this.id} />;
        break;

      case 'series':
        content = <SeriesContent id={this.id} jdata={this.jdata} />;
        break;

      case 'todo':
        break;

      default:
        content = <div className="warning">TODO: content: type={this.typ}</div>;
    }
    return (
      <div>
        <WarnOffline
          show={this.state.warn_offline}
          handler={this.handleReopenClick}
        />
        {content}
      </div>
    );
  }
}

// from Django to React
let div = document.getElementById('react');
if (div) {
  let typ = div.getAttribute('typ');
  let key = div.getAttribute('key');
  let data = div.getAttribute('data');

  let country = div.getAttribute('country');
  set_country(country);

  ReactDOM.render(<Content typ={typ} id={key} data={data} />, div);
} else {
  console.log('No React on this page');
}
// eof
