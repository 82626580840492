import BookCellCard2 from './BookCellCard2';

class SeriesRowCard extends React.Component {
  render() {
    let series = this.props.series;

    let books = null;
    let books2 = null;
    let related = null;
    let covers = null;

    let dot = '.';
    let blank = '';
    let space = ' ';
    /*
        let lbrac = '('
        let rbrac = ')'
                    { x.country ? lbrac : blank }
                    { x.country ? x.country : blank }
                    { x.country ? rbrac : blank }
                    */

    let sos_related = [];
    let sos_inseries = [];
    for (let i = 0; i < series.seriesorders.length; i++) {
      let so = series.seriesorders[i];
      if (so.ordr) {
        // keep same order
        sos_inseries.push(so);
      } else {
        // reverse order so most recent comes first
        sos_related.unshift(so);
      }
    }
    if (series.seriesorders) {
      // debugger
      books = sos_inseries.map((x) => (
        <li key={x.id} className="bigspaces">
          {x.ordr}
          {x.ordr2 ? dot : blank}
          {x.ordr2}
          {dot}
          {space}
          <a href={x.book.url}>{x.book.title}</a>
        </li>
      ));
      /*
            books2 = sos_inseries.map((x) =>
                <div className="cell small-3" key={x.id} >
                    <BookCellCard2 book={x.book} />
                </div>
            )
            */

      related = sos_related.map((x) => (
        <li key={x.id} className="bigspaces">
          <a href={x.book.url}>{x.book.title}</a>
        </li>
      ));
      covers = sos_inseries.map((x) => (
        <li key={x.id} className="bookcovers">
          <img src={x.book.image_url} className="bookcard_img" />
        </li>
      ));
    }
    return (
      <div className="card">
        <div className="card-section">
          <h2 className="trim-info">
            Series: <a href={series.url}>{series.name}</a>
          </h2>
          {space}, by {space}
          <a href={series.author.url} className="card-trim-info">
            {series.author.name}
          </a>
        </div>
        <div className="card-section">
          <div className="grid-x">
            <ul className="inlinelist">{books}</ul>
          </div>
        </div>
      </div>
    );
  }
}

export default SeriesRowCard;

{
  /* LATER
                <div className="card-section">
                    <ul className="no-bullet">
                        {books}
                    </ul>
                </div>
                { sos_related ? 'Related: ' : ''}
                <div className="card-section">
                    <ul className="no-bullet">
                        {related}
                    </ul>
                </div>
                <div className='card-section'>
                    <ul className="no-bullet">
                        {covers}
                    </ul>
                </div>
        */
}
