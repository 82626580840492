import moment from 'moment';
import { isEmptyDict, sortByFreshnessAndPrice } from '../functions';

import BookPricesCell from './BookPricesCell';
import Order from './Order';
import WatchBookButton from '../stateful/WatchBookButton';

function Title(x) {
  return (
    <div className="card-trim-info">
      <a href={x.url} className="card_book_title">
        {x.title}
      </a>
    </div>
  );
}

function Author(x) {
  return (
    <div className="card-trim-info">
      <a href={x.url} className="card_book_authorstring">
        {x.name}
      </a>
    </div>
  );
}

// function ImageWithUpvote(x) {
//   return (
//     <div className="row">
//       <div className="column small-6 medium-12">
//         <Image url={x.url}/>
//       </div>
//       <div className="column small-6 medium-12">
//         <Upvote score={x.score} className="float-center"/>
//       </div>
//     </div>
//   )
// }

function Lang(x) {
  if (x.lang && x.lang != 'eng') {
    return <span className="label">{x.lang}</span>;
  } else {
    return null;
  }
}

function MyPrice(x) {
  if (!x.price) {
    return null;
  }

  return (
    <div>
      <div className="watch_price">{x.price}</div>
      <p>
        <i>
          Last Changed:
          <br />
          {moment.unix(x.updated_ts).calendar()}
        </i>
      </p>
    </div>
  );
}
class BookCellCard2 extends React.Component {
  constructor(props) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    this.handleAuthorClick = this.handleAuthorClick.bind(this);
    this.handleBookClick = this.handleBookClick.bind(this);
  }

  handleBookClick(event) {
    event.stopPropagation();
    location.href = this.props.book.url;
  }

  handleAuthorClick(event) {
    event.stopPropagation();
    location.href = this.props.mainauthor.url;
  }

  render() {
    const book = this.props.book;
    const so = book.seriesorder;
    const has_seriesorder = !(so == undefined || isEmptyDict(so));

    return (
      <div className="card" onClick={this.handleBookClick}>
        <div className="card-section">
          <img className="bookcard_img" src={book.image_url} />
        </div>
        <div className="card-section">
          <Title title={book.title} url={book.book_url} />
          <Author
            name={book.authorstring}
            url={book.mainauthor.url}
            onClick={this.handleAuthorClick}
          />
          <Lang lang={book.lang} />
        </div>
        <div className="card-section">
          <div className="watch_price_hdr">Best Price</div>
          <div className="watch_price">{book.bestprice}</div>
        </div>
        <div className="card-section">
          <WatchBookButton id={book.id} />
        </div>
        <div className="card-section">
          <BookPricesCell blis={book.blis} />
        </div>
        <div className={'card-divider' + (has_seriesorder ? '' : ' hide')}>
          <Order seriesorder={book.seriesorder} />
        </div>
      </div>
    );
  }
}

export default BookCellCard2;

// eof
