class ShowPricesButton extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.toggle();
  }

  render() {
    return null;

    const visible = this.props.visible;
    return (
      <div>
        <button
          type="button"
          className="primary button tiny"
          onClick={this.handleClick}
        >
          {visible ? 'Hide' : 'Show'} Prices
        </button>
      </div>
    );
  }
}

export default ShowPricesButton;

//
