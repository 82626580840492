class SeriesSidebarBlock extends React.Component {
  render() {
    const series = this.props.series;

    return (
      <div>
        <a href={series.url}>{series.name}</a>
      </div>
    );
  }
}

export default SeriesSidebarBlock;
