var FontAwesome = require('react-fontawesome');

export function isEmptyDict(obj) {
  const rtn = JSON.stringify(obj) === '{}';
  // console.log('isEmptyDict: ' + rtn)
  return rtn;
}

export function contains(a, obj) {
  if (a === undefined) {
    debugger;
    return;
  }
  for (var i = 0; i < a.length; i++) {
    if (a[i] === obj) {
      return true;
    }
  }
  return false;
}

export function Upvote(x) {
  return (
    <span>
      <FontAwesome name="caret-up" className="scoreCaretUp" />
      <p>{x.score}</p>
    </span>
  );
}

export function removeFromListById(jdata, lst) {
  if (jdata.id === undefined) {
    debugger;
    console.error('jdata should have an id');
  }

  if (lst === undefined || lst === null) {
    debugger;
    return;
  }

  // remove old version if it exists
  for (let i = 0; i < lst.length; i++) {
    let x = lst[i];
    if (x.id === undefined) {
      debugger;
      console.error('x should have an id');
    }
    if (x.typ === jdata.typ && x.id === jdata.id) {
      lst.splice(i, 1);
      break;
    }
  }
}

export function sortByFreshness(a, b) {
  let rtn = null;
  if (a.changed_ts > b.changed_ts) {
    rtn = -1;
  } else if (a.changed_ts < b.changed_ts) {
    rtn = 1;
  } else {
    if (a.changed_ts == b.changed_ts) {
      return a.key > b.key ? 1 : -1;
    }
  }
  return rtn;
}

export function sortByFreshnessAndPrice(a, b) {
  let rtn = null;
  if (a.fresh && !b.fresh) {
    rtn = -1;
  } else if (!a.fresh && b.fresh) {
    rtn = 1;
  } else if (a.numericprice > b.numericprice) {
    rtn = 1;
  } else if (a.numericprice < b.numericprice) {
    rtn = -1;
  } else {
    rtn = 0;
  }
  return rtn;
}

export function sortById(a, b) {
  // debugger
  if (a.id === undefined || b.id === undefined) {
    debugger;
  }
  let rtn = null;
  if (a.id < b.id) {
    rtn = -1;
  } else if (a.id > b.id) {
    rtn = 1;
  } else {
    rtn = 0;
  }
  // console.log("rtn: " + rtn)
  return rtn;
}

export function sortByScore(a, b) {
  let rtn = null;
  if (a.score !== null && b.score === null) {
    // console.log("a.score not null")
    rtn = -1;
  } else if (b.score !== null && a.score === null) {
    // console.log("b.score not null")
    rtn = 0;
  } else if (a.score === null && b.score === null) {
    // console.log("both scores null")
    // console.log("using key")
    rtn = a.key > b.key ? -1 : 1;
  } else if (a.score > b.score) {
    // console.log("a.score >")
    rtn = -1;
  } else if (b.score > a.score) {
    // console.log("b.score >")
    rtn = 1;
  } else {
    rtn = a.key > b.key ? -1 : 1;
  }
  // console.log("rtn: " + rtn)
  return rtn;
}

export function sortByTitle(a, b) {
  let rtn = null;
  if (a.title_ordering < b.title_ordering) {
    rtn = -1;
  } else if (a.title_ordering > b.title_ordering) {
    rtn = 1;
  } else {
    rtn = 0;
  }
  return rtn;
}

// eof
