import SearchResultCard from '../stateless/SearchResultCard';
import SearchResultMinAuthor from '../stateless/SearchResultMinAuthor';
import SearchResultMinBook from '../stateless/SearchResultMinBook';
import SearchResultMinSeries from '../stateless/SearchResultMinSeries';

import { sortById, sortByScore } from '../functions';
import { contains, removeFromListById } from '../functions';

import {
  send_sub_book,
  send_unsub_book,
  send_sub_author,
  send_unsub_author,
  send_sub_search,
  send_unsub_search,
  send_sub_series,
  send_unsub_series,
  ws,
} from '../channels';

function WaitingForResults(props) {
  return <p>Waiting for the results.</p>;
}

function NoResults(props) {
  return <p>Sorry but we have not found any results.</p>;
}

function Result(props) {
  return <div className="row callout">{props.value}</div>;
}

function removeFromOldLists(jdata, scored, my_noscored) {
  removeFromListById(jdata, scored);
  removeFromListById(jdata, my_noscored);
}

class SearchContent extends React.Component {
  constructor(props) {
    super(props);
    this.id = props.id;

    this.state = {
      // required
      typ: 'search',
      id: this.id,

      // specific to this type
      fullwidth: [],
      cells: [],

      scored: [],
      noscored_authors: [],
      noscored_books: [],
      // noscored_isbns: [],
      noscored_series: [],
      // noscored_subjects

      no_results: null,
    };

    this._onOpen = this.onOpen.bind(this);
    ws.addEventListener('open', this._onOpen);

    this._onClose = this.onClose.bind(this);
    ws.addEventListener('close', this._onClose);

    this._onMessage = this.onMessage.bind(this);
    ws.addEventListener('message', this._onMessage);
  }

  onOpen(evt) {
    this.sub_search();
  }

  onClose(e) {
    this.unsub_search();
  }

  onMessage(evt) {
    let data = evt.data;
    let jdata = JSON.parse(data);
    // console.log('SearchContent.onMessage:' + jdata.typ)

    switch (jdata.typ) {
      case 'livelog':
      case 'watch_book':
        return;
    }

    let fullwidth = this.state.fullwidth;
    let cells = this.state.cells;

    let noscored_authors = this.state.noscored_authors;
    let noscored_books = this.state.noscored_books;
    let noscored_series = this.state.noscored_series;
    let my_noscored = null;
    let my_scored = null;
    let no_results = null;

    let fw_types = ['author', 'series'];
    let cell_types = ['book'];
    let sr_types = ['sr_author', 'sr_book', 'sr_series', 'sr_isbn'];

    let book_in_series = false;

    if (contains(sr_types, jdata.typ)) {
      if (jdata.action === 'SAVE') {
        jdata = JSON.parse(jdata.jresult);
        this.sub_obj(jdata);
      } else {
        jdata.typ = jdata.typ.replace('sr_', '');
      }
    }

    switch (jdata.typ) {
      case 'author':
      case 'person':
        my_noscored = noscored_authors;
        my_scored = fullwidth;
        no_results = false;
        break;

      case 'book':
      case 'isbn':
        my_noscored = noscored_books;
        my_scored = cells;
        no_results = false;
        break;

      case 'series':
        my_noscored = noscored_series;
        my_scored = fullwidth;
        no_results = false;
        break;

      case 'noresult':
        no_results = true;
        break;

      default:
        console.log('TODO: ' + jdata.typ);
    }

    // if (contains(['DEL', 'SAVE'], jdata.action)) {
    removeFromOldLists(jdata, my_scored, my_noscored);
    // }

    if (contains(['SAVE', 'GET'], jdata.action)) {
      switch (jdata.typ) {
        case 'book':
        case 'isbn':
          // look for book in :
          // 1. fullwidth e.g. scored_authors and series
          for (let i = 0; i < fullwidth.length; i++) {
            let x = fullwidth[i];
            if (!contains(fw_types, x.typ)) {
              continue;
            }
            if (x.typ == 'series') {
              for (let j = 0; j < x.seriesorders.length; j++) {
                let so = x.seriesorders[j];
                if (contains(jdata.aliases, so.book.id)) {
                  fullwidth[i].seriesorders[j].book = jdata;
                  this.setState({ fullwidth: fullwidth });
                  book_in_series = true;
                }
              }
            }
            if (x.typ == 'author') {
              for (let j = 0; j < x.series.length; j++) {
                let series = x.series[j];
                for (let j1 = 0; j1 < series.seriesorders.length; j1++) {
                  let so = series.seriesorders[j1];
                  if (contains(jdata.aliases, so.book.id)) {
                    fullwidth[i].series[j].seriesorders[j1].book = jdata;
                    this.setState({ fullwidth: fullwidth });
                    book_in_series = true;
                  }
                }
              }
            }
          }

          // 2. cells e.g. scored books
          for (let i = 0; i < cells.length; i++) {
            let x = cells[i];
            if (!contains(cell_types, x.typ)) {
              continue;
            }
            if (contains(jdata.aliases, x.id)) {
              cells[i] = jdata;
              this.setState({ cells: cells });
            }
          }
          // Ignore unscored
          break;

        case 'person':
        case 'series':
          break;

        default:
          console.log('TODO: handle my_noscored: ' + jdata.typ);
          return;
      }
    }

    switch (jdata.action) {
      case 'SAVE':
        if (jdata.score > 10) {
          my_scored.unshift(jdata);
          my_scored = my_scored.sort(sortByScore);
        } else {
          my_noscored.unshift(jdata);
          my_noscored = my_noscored.sort(sortById);
        }
        break;

      case 'DEL':
        switch (jdata.typ) {
          case 'book':
            send_unsub_book(jdata.id);
            break;

          case 'person':
          case 'author':
            send_unsub_author(jdata.id);
            break;

          case 'series':
            send_unsub_series(jdata.id);
            break;

          default:
            console.log('TODO: send_unsub: jdata.typ: ' + jdata.typ);
        }
        break;

      case 'GET':
        break;

      default:
        console.log('TODO: handle action ' + jdata.action);
    }

    // remove book from scored/unscored if it's already in a series
    if (jdata.typ == 'book' && book_in_series) {
      removeFromOldLists(jdata, my_scored, my_noscored);
    }

    let num_results =
      fullwidth.length +
      cells.length +
      noscored_authors.length +
      noscored_books.length +
      noscored_series.length;

    this.setState({
      fullwidth: fullwidth,
      cells: cells,

      // scored: scored,
      noscored_authors: noscored_authors,
      noscored_books: noscored_books,
      noscored_series: noscored_series,
      num_results: num_results,
      no_results: no_results,
    });
  }

  sub_obj(jdata) {
    switch (jdata.typ) {
      case 'book':
        send_sub_book(jdata.id);
        break;

      case 'person':
        send_sub_author(jdata.id);
        break;

      case 'series':
        send_sub_series(jdata.id);
        break;

      default:
        console.log('TODO: sub_obj: ' + jdata.typ);
    }
  }

  sub_search() {
    send_sub_search(this.id);
  }

  unsub_search() {
    send_unsub_search(this.id);
  }

  render() {
    const fullwidth = this.state.fullwidth;
    const cells = this.state.cells;

    const noscored_authors = this.state.noscored_authors;
    const noscored_books = this.state.noscored_books;
    const noscored_series = this.state.noscored_series;
    const num_results = this.state.num_results;
    const no_results = this.state.no_results;

    const fullwidth_cards = fullwidth.map((x) => (
      <div className="cell" key={x.id}>
        <SearchResultCard sr={x} />
      </div>
    ));
    const cells_cards = cells.map((x) => (
      <div className="cell small-3" key={x.id}>
        <SearchResultCard sr={x} />
      </div>
    ));

    const nsa = noscored_authors.map((x) => (
      <SearchResultMinAuthor key={x.id} author={x} />
    ));
    const nsb = noscored_books.map((x) => (
      <SearchResultMinBook key={x.id} book={x} />
    ));
    const nss = noscored_series.map((x) => (
      <SearchResultMinSeries key={x.id} series={x} />
    ));

    const waiting = num_results === 0 && no_results !== true;

    // console.log('no_results: ' + no_results)
    // console.log('waiting: ' + waiting)
    // console.log('num_results: ' + num_results)
    return (
      <div id="SearchResults">
        {waiting && <WaitingForResults />}
        {no_results && <NoResults />}

        <div className="grid-x">
          {fullwidth_cards}
          {cells_cards}
        </div>

        {nsa.length ? (
          <div className="card">
            <div className="card-divider">
              <h2>Authors</h2>
            </div>
            <div className="card-section">
              <ul className="inlinelist">{nsa}</ul>
            </div>
          </div>
        ) : (
          ''
        )}

        {nsb.length ? (
          <div className="card">
            <div className="card-divider">
              <h2>Books</h2>
            </div>
            <div className="card-section">
              <ul className="inlinelist">{nsb}</ul>
            </div>
          </div>
        ) : (
          ''
        )}

        {nss.length ? (
          <div className="card">
            <div className="card-divider">
              <h2>Series</h2>
            </div>
            <div className="card-section">
              <ul className="inlinelist">{nss}</ul>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default SearchContent;
