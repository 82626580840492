import RobustWebsocket from 'robust-websocket';

export let ws = null;
let country = null;

let ws_waiting = [];

export function set_ws(new_ws) {
  ws = new_ws;
  ws.addEventListener('open', send_waiting);
  return ws;
}

export function set_country(new_country) {
  country = new_country;
  return country;
}

function send_waiting() {
  // console.log('send_waiting: ' + ws_waiting.length + ' to send')
  for (let i = 0; i < ws_waiting.length; i++) {
    let jmsg = ws_waiting.shift();
    ws.send(jmsg);
  }
}

function log_ws_state(ws) {
  switch (ws.readyState) {
    case 0:
      console.warn('CONNECTING');
      break;

    case 1:
      console.log('OPEN');
      break;

    case 2:
      console.warn('CLOSING');
      break;

    case 3:
      console.warn('CLOSED');
      break;

    default:
      console.warn('unknown state');
      break;
  }
}

function send(action, typ, id, state = null) {
  // console.log('send: ' + action + ' ' + typ + ' ' + id)
  let msg = {
    action: action,
    typ: typ,
    id: id,
    country: country,
    state: state,
  };
  let jmsg = JSON.stringify(msg);
  if (ws.readyState == 1) {
    ws.send(jmsg);
  } else {
    ws_waiting.push(jmsg);
  }
}

// admin online

export function send_sub_admin_online() {
  send('SUB', 'admin_online', null);
}

export function send_unsub_admin_online() {
  send('UNSUB', 'admin_online', null);
}

// admin livelog

export function send_sub_admin_livelog() {
  send('SUB', 'livelog', null);
}

export function send_unsub_admin_livelog() {
  send('UNSUB', 'livelog', null);
}

// author

export function send_sub_author(id) {
  send('SUB', 'author', id);
}

export function send_unsub_author(id) {
  send('UNSUB', 'author', id);
}

// export function send_get_author_books(id, orderby, startafter, qty) {
//     let state = orderby + '+' + startafter + '+' + qty
//     send('GET', 'author_books', id, state=state)
// }

// // blis

// export function send_sub_blis(ws, id, country) {
//     send(ws, 'SUB', 'blis', id, country=country)
// }

// export function send_unsub_blis(ws, id, country) {
//     send(ws, 'UNSUB', 'blis', id, country=country)
// }

// book

export function send_sub_book(id) {
  // console.log('send_sub_book: ' + id)
  send('SUB', 'book', id);
}

export function send_unsub_book(id) {
  send('UNSUB', 'book', id);
}

// bookcover

// export function send_get_bookcover(ws, id, country) {
//     // console.log('get_bookcover: ' + id)
//     send(ws, 'GET', 'bookcover', id, country=country)
// }

// bookstream

export function send_sub_bookstream(id) {
  send('SUB', 'bookstream', id);
}

export function send_unsub_bookstream(id) {
  send('UNSUB', 'bookstream', id);
}

// booktagupdates

export function send_sub_booktagupdates(id) {
  send('SUB', 'booktagupdates', id);
}

export function send_unsub_booktagupdates(id) {
  send('UNSUB', 'booktagupdates', id);
}

// search

export function send_sub_search(id) {
  send('SUB', 'search', id);
}

export function send_unsub_search(id) {
  send('UNSUB', 'search', id);
}

// series

export function send_sub_series(id) {
  send('SUB', 'series', id);
}

export function send_unsub_series(id) {
  send('UNSUB', 'series', id);
}

// watch

export function change_watch_author(id, state) {
  send('CHANGE', 'watch_author', id, state);
}

export function get_watch_author(id) {
  send('GET', 'watch_author', id);
}

export function change_watch_book(id, state) {
  send('CHANGE', 'watch_book', id, state);
}

export function get_watch_book(id) {
  send('GET', 'watch_book', id);
}

// eof
