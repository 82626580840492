function Title(x) {
  return (
    <div className="card-trim-info">
      <a href={x.url} className="card_book_title">
        {x.title}
      </a>
    </div>
  );
}

// function Subtitle(x) {
//   return (
//     <span className="card-trim-info card_subtitle">
//       {x.subtitle}
//     </span>
//   )
// }

function Author(x) {
  return (
    <div className="card-trim-info">
      <a href={x.url} className="card_book_authorstring">
        {x.name}
      </a>
    </div>
  );
}

// function ImageWithUpvote(x) {
//   return (
//     <div className="row">
//       <div className="column small-6 medium-12">
//         <Image url={x.url}/>
//       </div>
//       <div className="column small-6 medium-12">
//         <Upvote score={x.score} className="float-center"/>
//       </div>
//     </div>
//   )
// }

function Lang(x) {
  if (x.lang && x.lang != 'eng') {
    return <span className="label">{x.lang}</span>;
  } else {
    return null;
  }
}

class BookCellCard extends React.Component {
  constructor(props) {
    super(props);

    this.book = props.book;

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    location.href = this.book.url;
  }

  render() {
    let x = this.book;
    debugger;
    return (
      <div className="card" onClick={this.handleClick}>
        <img className="bookcard_img" src={x.image_url} />
        <div className="card-section">
          <Title title={x.title} url={x.book_url} />
          <Author name={x.authorstring} url={x.mainauthor.url} />
          <Lang lang={x.lang} />
        </div>
      </div>
    );
  }
}

export default BookCellCard;

// eof
