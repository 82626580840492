class SearchResultMinSeries extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const series = this.props.series;
    const space = ' ';
    return (
      <li className="bigspaces">
        The {space}
        <a href={series.url}>{series.name}</a>
        {space}Series by {space}
        <a href={series.author.url}>{series.author.name}</a>
      </li>
    );
  }
}

export default SearchResultMinSeries;
