class SearchResultMinAuthor extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const author = this.props.author;
    return (
      <li className="bigspaces">
        <a href={author.url}>{author.name}</a>
      </li>
    );
  }
}

export default SearchResultMinAuthor;
