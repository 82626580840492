function Label(x) {
  if (!x.bool) {
    return null;
  }
  return (
    <li>
      <span className="label">{x.txt}</span>
    </li>
  );
}

function BookLabels(x) {
  if (!x.labels) {
    return null;
  }
  return (
    <ul className="no-bullet inlinelist">
      <Label bool={x.labels.kindle_unlimited} txt="KindleUnlimited" />
      <Label bool={x.labels.shortbook} txt="Short Book" />
      <Label bool={x.labels.drm_free} txt="DRM-Free" />
      <Label bool={x.labels.primelending} txt="Prime Lending" />
      <Label bool={x.labels.pre_order} txt="Pre-Order" />
      <Label bool={x.labels.early_release} txt="Early Release" />
    </ul>
  );
}

export default BookLabels;
