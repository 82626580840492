import {
  send_sub_booktagupdates,
  send_unsub_booktagupdates,
  ws,
} from '../channels';

import { contains, removeFromListById, sortByFreshness } from '../functions';

import BookCellCard2 from '../stateless/BookCellCard2';

// DRY: very similar to BookStream

class MyPricesContent extends React.Component {
  constructor(props) {
    super(props);
    this.id = props.id;

    this.state = {
      // required
      typ: 'myPrices',
      id: this.id,

      // specific to this type
      books: [],
    };

    this._onOpen = this.onOpen.bind(this);
    ws.addEventListener('open', this._onOpen);

    this._onClose = this.onClose.bind(this);
    ws.addEventListener('close', this._onClose);

    this._onMessage = this.onMessage.bind(this);
    ws.addEventListener('message', this._onMessage);
  }

  onOpen(evt) {
    this.sub_prices();
  }

  onClose(evt) {
    this.unsub_prices();
  }

  onMessage(evt) {
    let data = evt.data;
    let jdata = JSON.parse(data);

    switch (jdata.typ) {
      case 'livelog':
      case 'watch_book':
        return;
    }

    switch (jdata.typ) {
      case 'btu': // aka BookTagUpdate
        let books = this.state.books;
        removeFromListById(jdata, books);

        if (jdata.action == 'SAVE') {
          // add this one
          books.unshift(jdata);
        } else if (jdata.action != 'DEL') {
          console.log('TODO: handle action ' + jdata.action);
        }

        this.sortAndSet(books);
        break;

      default:
        console.error('myPricesContent: typ: ' + jdata.typ);
    }
  }

  sortAndSet(books) {
    let max_books = 20;
    let new_books = books.sort(sortByFreshness);
    let top_books = new_books.slice(0, max_books);

    this.setState({
      books: top_books,
    });
  }

  sub_prices() {
    send_sub_booktagupdates(this.id);
  }

  unsub_prices() {
    send_unsub_booktagupdates(this.id);
  }

  render() {
    const cards = this.state.books.map((book) => (
      <div key={book.id} className="cell small-6 medium-3">
        <BookCellCard2 key={book.id} book={book} />
      </div>
    ));
    return (
      <div>
        <p className="lead">
          Here's your most recent prices on the books you watch.
        </p>

        <div className="grid-x">{cards}</div>
      </div>
    );
  }
}

export default MyPricesContent;

// eof
